var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('base-card',[_c('v-progress-linear',{attrs:{"active":_vm.loading,"indeterminate":_vm.loading,"absolute":"","bottom":"","color":"#6ec1ff"}}),_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('availability'))+" "),_c('v-spacer'),_c('v-spacer')],1),_c('v-card-text',[_c('v-select',{attrs:{"items":_vm.a_SeminarCenter,"item-value":"i_SeminarCenterID","item-text":"s_Name","label":"Standort","dense":"","outlined":""},model:{value:(_vm.i_SelectedSeminarCenterID),callback:function ($$v) {_vm.i_SelectedSeminarCenterID=$$v},expression:"i_SelectedSeminarCenterID"}}),_c('v-data-table',{staticClass:"elevation-1 table-one",attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.a_SeminarCenterSlots,"items-per-page":20,"item-key":"name","dense":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.monday",fn:function(ref){
var item = ref.item;
return [[_c('v-chip',{attrs:{"color":_vm.getColorChip(item.monday),"label":"","small":"","text-color":_vm.getColorChip(item.monday),"outlined":""},on:{"click":function($event){return _vm.setAvailability(item, 'monday')}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" "+_vm._s(_vm.getIconChip(item.monday))+" ")]),_vm._v(" "+_vm._s(_vm.getLabelChip(item.monday))+" ")],1)]]}},{key:"item.tuesday",fn:function(ref){
var item = ref.item;
return [[_c('v-chip',{attrs:{"color":_vm.getColorChip(item.tuesday),"label":"","small":"","text-color":_vm.getColorChip(item.tuesday),"outlined":""},on:{"click":function($event){return _vm.setAvailability(item, 'tuesday')}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" "+_vm._s(_vm.getIconChip(item.tuesday))+" ")]),_vm._v(" "+_vm._s(_vm.getLabelChip(item.tuesday))+" ")],1)]]}},{key:"item.wednesday",fn:function(ref){
var item = ref.item;
return [[_c('v-chip',{attrs:{"color":_vm.getColorChip(item.wednesday),"label":"","small":"","text-color":_vm.getColorChip(item.wednesday),"outlined":""},on:{"click":function($event){return _vm.setAvailability(item, 'wednesday')}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" "+_vm._s(_vm.getIconChip(item.wednesday))+" ")]),_vm._v(" "+_vm._s(_vm.getLabelChip(item.wednesday))+" ")],1)]]}},{key:"item.thursday",fn:function(ref){
var item = ref.item;
return [[_c('v-chip',{attrs:{"color":_vm.getColorChip(item.thursday),"label":"","small":"","text-color":_vm.getColorChip(item.thursday),"outlined":""},on:{"click":function($event){return _vm.setAvailability(item, 'thursday')}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" "+_vm._s(_vm.getIconChip(item.thursday))+" ")]),_vm._v(" "+_vm._s(_vm.getLabelChip(item.thursday))+" ")],1)]]}},{key:"item.friday",fn:function(ref){
var item = ref.item;
return [[_c('v-chip',{attrs:{"color":_vm.getColorChip(item.friday),"label":"","small":"","text-color":_vm.getColorChip(item.friday),"outlined":""},on:{"click":function($event){return _vm.setAvailability(item, 'friday')}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" "+_vm._s(_vm.getIconChip(item.friday))+" ")]),_vm._v(" "+_vm._s(_vm.getLabelChip(item.friday))+" ")],1)]]}},{key:"item.saturday",fn:function(ref){
var item = ref.item;
return [[_c('v-chip',{attrs:{"color":_vm.getColorChip(item.saturday),"label":"","small":"","text-color":_vm.getColorChip(item.saturday),"outlined":""},on:{"click":function($event){return _vm.setAvailability(item, 'saturday')}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" "+_vm._s(_vm.getIconChip(item.saturday))+" ")]),_vm._v(" "+_vm._s(_vm.getLabelChip(item.saturday))+" ")],1)]]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }