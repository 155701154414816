<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-progress-linear
          :active="loading"
          :indeterminate="loading"
          absolute
          bottom
          color="#6ec1ff"
        />
        <v-card-title>
          {{$t('availability')}}
          <v-spacer />
          <v-spacer />
        </v-card-title>
        <v-card-text>
          <v-select
            v-model="i_SelectedSeminarCenterID"
            :items="a_SeminarCenter"
            item-value="i_SeminarCenterID"
            item-text="s_Name"
            label="Standort"
            dense
            outlined
          />
          <v-data-table
            v-model="selected"
            :search="search"
            :headers="headers"
            :items="a_SeminarCenterSlots"
            :items-per-page="20"
            item-key="name"
            class="elevation-1 table-one"
            dense
            hide-default-footer
          >
            <template v-slot:item.monday="{item}">
              <template>
                <v-chip
                  class=""
                  :color="getColorChip(item.monday)"
                  label
                  small
                  :text-color="getColorChip(item.monday)"
                  outlined
                  @click="setAvailability(item, 'monday')"
                >
                  <v-icon
                    small
                    left
                  >
                    {{ getIconChip(item.monday) }}
                  </v-icon>
                  {{ getLabelChip(item.monday) }}
                </v-chip>
              </template>
            </template>
            <template v-slot:item.tuesday="{item}">
              <template>
                <v-chip
                  class=""
                  :color="getColorChip(item.tuesday)"
                  label
                  small
                  :text-color="getColorChip(item.tuesday)"
                  outlined
                  @click="setAvailability(item, 'tuesday')"
                >
                  <v-icon
                    small
                    left
                  >
                    {{ getIconChip(item.tuesday) }}
                  </v-icon>
                  {{ getLabelChip(item.tuesday) }}
                </v-chip>
              </template>
            </template>
            <template v-slot:item.wednesday="{item}">
              <template>
                <v-chip
                  class=""
                  :color="getColorChip(item.wednesday)"
                  label
                  small
                  :text-color="getColorChip(item.wednesday)"
                  outlined
                  @click="setAvailability(item, 'wednesday')"
                >
                  <v-icon
                    small
                    left
                  >
                    {{ getIconChip(item.wednesday) }}
                  </v-icon>
                  {{ getLabelChip(item.wednesday) }}
                </v-chip>
              </template>
            </template>
            <template v-slot:item.thursday="{item}">
              <template>
                <v-chip
                  class=""
                  :color="getColorChip(item.thursday)"
                  label
                  small
                  :text-color="getColorChip(item.thursday)"
                  outlined
                  @click="setAvailability(item, 'thursday')"
                >
                  <v-icon
                    small
                    left
                  >
                    {{ getIconChip(item.thursday) }}
                  </v-icon>
                  {{ getLabelChip(item.thursday) }}
                </v-chip>
              </template>
            </template>
            <template v-slot:item.friday="{item}">
              <template>
                <v-chip
                  class=""
                  :color="getColorChip(item.friday)"
                  label
                  small
                  :text-color="getColorChip(item.friday)"
                  outlined
                  @click="setAvailability(item, 'friday')"
                >
                  <v-icon
                    small
                    left
                  >
                    {{ getIconChip(item.friday) }}
                  </v-icon>
                  {{ getLabelChip(item.friday) }}
                </v-chip>
              </template>
            </template>
            <template v-slot:item.saturday="{item}">
              <template>
                <v-chip
                  class=""
                  :color="getColorChip(item.saturday)"
                  label
                  small
                  :text-color="getColorChip(item.saturday)"
                  outlined
                  @click="setAvailability(item, 'saturday')"
                >
                  <v-icon
                    small
                    left
                  >
                    {{ getIconChip(item.saturday) }}
                  </v-icon>
                  {{ getLabelChip(item.saturday) }}
                </v-chip>
              </template>
            </template>
          </v-data-table>
        </v-card-text>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
  import { get, post } from '../../../worker/worker-api'
  import { mapGetters } from 'vuex'
  import { config } from '../../../data/config'
  export default {
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'Verfügbarkeit',
    },
    data () {
      return {
        search: '',
        selected: [],
        headers: [
          {
            text: 'Zeit',
            align: 'start',
            sortable: false,
            value: 'time',
          },
          { text: 'Montag', value: 'monday' },
          { text: 'Dienstag', value: 'tuesday' },
          { text: 'Mittwoch', value: 'wednesday' },
          { text: 'Donnerstag', value: 'thursday' },
          { text: 'Freitag', value: 'friday' },
          { text: 'Samstag', value: 'saturday' },
        ],
        slots: [],
        a_SeminarCenter: [],
        i_SelectedSeminarCenterID: null,
        loading: true,
        i_TacherAddressRoleID: null,
      }
    },
    watch: {
      a_SeminarCenter (val) {
        if (val.length > 0) this.i_SelectedSeminarCenterID = val[0].i_SeminarCenterID
      },
      i_PlanningPeriodID (val) {
        this.loading = true
        this.a_SeminarCenter = []
        this.slots = []
        this.getAvailability()
      }
    },
    computed: {
      ...mapGetters(['getPlanningPeriodID', 'getImpersonation']),
      a_SeminarCenterSlots () {
        return this.slots.filter(slot => slot.i_SeminarCenterID === this.i_SelectedSeminarCenterID)
      },
      i_PlanningPeriodID () {
        return this.getPlanningPeriodID
      }
    },
    methods: {
      getAvailability () {
        get(`${process.env.VUE_APP_SMT_API_URL}/teacher/${this.i_TacherAddressRoleID}/period/${this.getPlanningPeriodID}/availability`, {
        })
          .then(response => {
            if (response.status === 401) {
              this.signOut()
              this.$smt.logout()
              return
            }
            this.slots = response.a_Slots
            this.a_SeminarCenter = response.a_SeminarCenter
            this.loading = false
          })
          .catch(error => {
            console.log('Worker error: ', error)
          })
      },
      setAvailability (Ao_Slot, As_Day) {
        post(`${process.env.VUE_APP_SMT_API_URL}/teacher/${this.i_TacherAddressRoleID}/period/${this.getPlanningPeriodID}/availability`, {
          i_LessonTimeID: Ao_Slot.i_LessonTimeID,
          i_AvailabilityTypeID: this.getNextState(Ao_Slot, As_Day),
          i_DayID: this.getDayID(As_Day),
          i_AvailabilityID: this.getAvailabilityID(Ao_Slot.availability, As_Day) 
        })
        .then(response => {
          this.editMode=false; 
          if (response.status === 401) {
            this.signOut()
            this.$smt.logout()
            return
          }
          this.setNextState(Ao_Slot.time, As_Day, this.getNextState(Ao_Slot, As_Day));
          this.requestSent = true;
          this.responseMessage = 'Verfügbarkeit gesendet';
        })
        .catch(error => {
          console.log('Worker error: ', error)
        })
      },
      getAvailabilityID(Aa_Availability, As_Day) {
        const Li_DayID = this.getDayID(As_Day);
        const La_Availability = Aa_Availability.filter(availability => availability.i_DayID === Li_DayID);
        if(La_Availability.length > 0) return La_Availability[0].i_AvailabilityID;
        else return null;
      },
      getDayID(As_Day) {
        switch (As_Day) {
          case 'monday':
            return 1;
          case 'tuesday':
            return 2;
          case 'wednesday':
            return 3;
          case 'thursday':
            return 4;
          case 'friday':
            return 5;
          case 'saturday':
            return 6;
          default:
            return null;
        }
      },
      getNextState(Ao_Slot, As_Day) {
        if(Ao_Slot[As_Day] === 1) return 2;
        if(Ao_Slot[As_Day] === 2) return 3;
        if(Ao_Slot[As_Day] === 3) return 1;
        return 3;
      },
      setNextState(As_Time, As_Day, Ai_NextState) {
        const f_SelectedSlot = (slot) => slot.time === As_Time && slot.i_SeminarCenterID == this.i_SelectedSeminarCenterID;
        const i_SlotIndex = this.slots.findIndex(f_SelectedSlot);
        this.slots[i_SlotIndex][As_Day] = Ai_NextState;
      },
      getColorChip(Ai_State) {
        switch (Ai_State) {
          case 1:
            return 'success';

          case 2:
            return 'orange';

          case 3:
            return 'danger';

          default:
            return 'danger';
        }
      },
      getIconChip(Ai_State) {
        switch (Ai_State) {
          case 1:
            return 'mdi-check';

          case 2:
            return 'mdi-exclamation';

          case 3:
            return 'mdi-close';

          default:
            return 'mdi-close';
        }
      },
      getLabelChip(Ai_State) {
        switch (Ai_State) {
          case 1:
            return 'Verfügbar';

          case 2:
            return 'Wahrscheinlich';

          case 3:
            return 'nicht Verfügbar';

          default:
            return 'nicht Verfügbar';
        }
      },
    },
    beforeMount() {
      if (this.getImpersonation.b_Status && this.getImpersonation.s_Role === config.roles[1]) this.i_TacherAddressRoleID = this.getImpersonation.i_AddressRoleID
      else this.i_TacherAddressRoleID = this.$smt.getAddressRoleID(this.$route.meta.role)
      this.getAvailability()
    },
  }
</script>
<style lang="scss" scoped>
::v-deep .table-one {
    thead.v-data-table-header {
        tr {
            &:hover {
                background-color: #f2f3f8;
            }
            th {
                span {
                    font-size: 16px;
                    color: #304156;
                }
            }
        }
        tr {
            td {
                padding-bottom: 20px;
                padding-top: 20px;
            }
        }
    }
    tbody {
        tr {
            &:hover {
                background-color: #f2f3f8 !important;
            }
        }
    }
}
</style>